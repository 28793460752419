import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import styled from 'styled-components';
import STYLES from '../constants/STYLES';
import tinycolor from 'tinycolor2';

const Navigation = styled.section`
  top: ${props => props.show || props.fixed ? '0' : '-100px'};
  transition: all 600ms cubic-bezier(0.86, 0, 0.07, 1);
  width: 100%;
  position: ${props => props.fixed ? 'relative' : 'fixed'};
  padding: 24px;
  z-index: ${props => props.fixed ? 0 : 9000};
  .title, .title:visited {
    color: ${props => props.isDark && !props.fixed ? STYLES.background : STYLES.black} !important;
    font-weight: 800;
    margin-right: ${STYLES.sPad};
    &:visited {
      color: ${STYLES.black}
    }
  }
  .subtitle {
    font-weight: 400;
    color: ${props => props.isDark && !props.fixed ? STYLES.background : STYLES.black} !important;
    @media (max-width: 600px) {
      display: none;
    }
  }
  a {
    color: ${props => props.isDark && !props.fixed ? STYLES.background : STYLES.black} !important;
    text-decoration: none;
  }
  .nav-wrap{
    display: flex;
    justify-content: space-between;
    // max-width: ${STYLES.maxBodyWidth}px;
    margin: 0 auto;
  }
  div {
    margin-right: ${STYLES.xlPad};
    @media (max-width: 600px) {
      margin-right: 0;
    }

  }
  background-color: ${props => props.color};

  box-shadow: ${props => props.fixed ? 'none' : '0 0 30px 0 rgba(0,0,0,0.05)'};
  > div {
    width: ${STYLES.maxBodyWidth};
  }
  .links {
    a:first-child {
      margin-right: ${STYLES.mPad};
    }
  }
`;

class Nav extends Component {
state = {
  showNav: true
}
componentDidMount = () => {
    let prev = 0;
    window.scrollTo(0, 0);
    window.addEventListener('scroll', () => {
      var scrollTop = window.scrollY;
      var scrollTop2 = document.documentElement.scrollTop;
      this.setState({ showNav: scrollTop2 < 200 || scrollTop < 200 || scrollTop < prev || scrollTop2 < prev});
      prev = scrollTop;
    });
  }
  render() {
    const color = this.props.fixed ? STYLES.lightGray : this.props.color;
    const isDark = tinycolor(this.props.color).isDark()
    return (
      <Navigation color={color} isDark={isDark} fixed={this.props.fixed} show={this.state.showNav}>
        <div className="nav-wrap">
          <div>
            <Link className="title" to="/">Under Glass</Link>
          </div>
          <div className="links">
          <Link to="/about">About</Link>
          <Link to="/signup">Subscribe</Link>
          </div>
        </div>
      </Navigation>
    )
  };
}
export default Nav;