import axios from 'axios';
import config from '../config';

export const FETCH_POSTS = 'FETCH_POSTS';
export const FETCH_SINGLE_POST = 'FETCH_SINGLE_POST';
export const FETCH_ASSETS = 'FETCH_ASSETS';
export const RESET_POSTS = 'RESET_POSTS';


const env = process.env.REACT_APP_STAGE || 'prod';
const { API_BASE_URL, API_SPACE_ID, API_TOKEN, CONTENT_TYPE } = config[env];

export function fetchPosts() {
  const request = axios.get(`${API_BASE_URL}/spaces/${API_SPACE_ID}/entries?access_token=${API_TOKEN}&content_type=${CONTENT_TYPE}&select=fields.title,fields.slug,fields.featuredImage,fields.logo,fields.productColor,fields.country,sys.id&include=2&order=-sys.createdAt`);
  return {
    type: FETCH_POSTS,
    payload: request
  };
}

export function fetchSinglePost(postName) {
  const request = axios.get(`${API_BASE_URL}/spaces/${API_SPACE_ID}/entries?access_token=${API_TOKEN}&content_type=${CONTENT_TYPE}&fields.title[match]=${postName}`);
  return {
    type: FETCH_SINGLE_POST,
    payload: request
  };
}


export function resetPosts() {
  return {
    type: RESET_POSTS
  };
}

export function fetchAssets() {
  const request = axios.get(`${API_BASE_URL}/spaces/${API_SPACE_ID}/assets?access_token=${API_TOKEN}`);
  return {
    type: FETCH_ASSETS,
    payload: request
  };
}




