// GLOBAL IMPORTS
import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
// LOCAL IMPORTS
import { fetchSinglePost } from '../actions/index';
import STYLES from '../constants/STYLES';
import Player from '@vimeo/player';
import { Link as ScrollLink } from 'react-scroll';
import { Email, Nav, Loader } from '../components';
import { LinkedIn, Twitter, Facebook, EmailIcon } from '../assets';
import moment from 'moment';
import tinycolor from 'tinycolor2';


const Logo = styled.img`
  display: inline-block;
  background-color: #FCFCFC;
  padding: 8px;
  width: 56px;
  height: 56px;
  margin-top: 14px;
  @media (max-width: 600px) {
    display: block;
    margin: 0 auto;
    width: 80px;
    height: 80px;
    margin-top: -48px;
    border-radius: 50%;
  }
`;

const Header = styled.section`
  width: 100%;
  height: 88vh;
  padding-top: 100px;
  background-repeat: no-repeat;
  background-size: 23%;
  background-image: url(${props => props.url});
  background-color: ${props => props.color};
  background-position: center;
  @media (max-width: 950px) {
    background-size: 35%;
  }
  @media (max-width: 600px) {
    height: 10vh;
    background-image: none;
  }
`;
const SocialLinkWrap = styled.section`
  display: flex;
  margin-top: ${STYLES.lPad};
`;
const SocialLink = styled.a`
  width: 40px;
  height: 40px;
  img {
    width: 20px;
    height: 20px;
  }
  padding: 10px;
  display: flex;
  justify-content: center;
  align-itemrs: center;
  background-color: ${STYLES.black};

`;

const ContentWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
`;
const SidebarWrap = styled.section`
  width: 20%;
  padding: ${STYLES.mPad};
  .product-title {
    display: block;
    font-weight: 600;
    font-size: 48px;
    margin: 0;
  }
  .country {
    font-size: 15px;
  }
  .info {
    p {
      font-size: 15px;
      color: #A5B1BC;
    }
    margin-top: ${STYLES.lPad};
    padding: ${STYLES.mPAd};
    border-top: 1px solid #eff1e8;
    border-bottom: 1px solid #eff1e8;
  }
  @media (max-width: 860px) {
    text-align: center;
    position: relative;
    top: 0;
    width: 100%;
    display: block;
    .display {
      margin: 0;
      text-align: center;
      font-size: ${STYLES.h1};
    }
  }
`;
const ArticleInfo = styled.section`
  
`;

const EntryBody = styled.section`
  max-width: ${STYLES.maxBodyWidth}px;
  margin-left: 3rem;
  margin-top: 3rem;
  position: relative;
  z-index: 5;
  pre {
    margin: 0;
    margin-top: -1rem;
    background: rgba(144,232,188,0.26);
    border: 1px solid #81EBB6;
    border-left: 8px solid #81EBB6;
    border-bottom-right-radius: 16px;
    padding: 16px;
    padding-left: 38px;
    white-space: normal;
    line-height: 24px;
  }
  h2 + p, p:first-child {
    text-indent: 0;
  }
  h2 {
    font-weight: 800;
    margin-top: 2rem;
    &:after {
      content: " ";
      width: 50px;
      margin-top: 1rem;
      display: block;
      height: 3px;
      background-color: ${props => props.color};
    }
  }
  }
  p {
    line-height: 1.65;
    margin-bottom: 0;
  }
  a {
    &:hover {
      background: ${props => props.color};
    }
  }
  p > img  { 
    display: block;
    margin-top: 3rem;
    max-width: 50%;
    margin-left: 25%;
    margin-bottom: 2rem;
    &:after {
      content: " ";
      width: 10px;
      height: 10px;
    }
    box-shadow: 0 0 30px 0 rgba(0,0,0,0.08);
  }
  img[src$='#full'] { 
    max-width: 100%;
    margin: 3rem auto 1.5rem auto;
  }
  iframe > * {
    opacity: .2
  }
  iframe {
    margin-left: 25%;
    margin-top: 2rem;
    max-width: 50%;
    height: 512px;
    box-shadow: 0 0 30px 0 rgba(0,0,0,0.08);
    opacity: .6;
    &:hover {
      opacity: 1;
    }
    @media (max-width: 600px) {
      margin: 1rem auto;
      max-width: 100%;
      display: block;
    }  
  }
  iframe[src$='#full'] { 
    max-width: unset;
    height: 360px;
    margin: 3rem auto 1.5rem auto;
    @media (max-width: 600px) {
      height: auto;
      width: auto;
    }
  }
  blockquote {
    margin: 2rem 4rem;
    p {
      font-size: ${STYLES.body};
      line-height: 1.65;
      color: ${STYLES.darkGray};
      font-style: italic;
    }
  }
  .img-desc {
    text-align: center;
    padding-top: 0px;
    line-height: 1.6;
    max-width: 75%;
    margin: 0 auto;
    font-family: monospace;
    color: ${STYLES.Gray};
  }
  ul, ol {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  ol {
    counter-reset: ug-counter;
  }

  li {
    position: relative;
    padding: 16px 16px 16px 36px;
    margin: 1rem 0;
    line-height: 1.65;
    border: 1px solid ${props => tinycolor(props.color).lighten(20).toHexString()};
    border-radius: 2px;
    background-color: white;
  }

  li:before {
    content: "";
    width: 0;
    height: 0;
    display: block;
    float: left;
    margin-left: -16px;
    margin-top: 8px;
  }

  ol li:before {
    content: counter(ug-counter);
    counter-increment: ug-counter;
    color: ${props => props.color};
    font-size: .8rem;
    position: absolute;
    top: .65rem;
    left: 2rem;
  }

  .flex {
    display: flex;
    img {
      max-width: 50%;
      height: auto;
    }
  }
  @media (max-width: 600px) {
    padding: 16px;
    margin: 0 auto;
  }
`;

class Article extends Component {
  state = {
    titles: []
  }
  async componentWillMount() {
    await this.props.fetchSinglePost(this.props.match.params.artnum);
    this.initializeVideos();
  }

  handleScroll = () => {
    
    
  }

  initializeVideos = () => {
    const videos = document.getElementsByTagName('iframe');
    // if videos are not initialized, call again .4s later
    if (!videos[0]) {
      return setTimeout(() => {
        this.initializeVideos();
      }, 400);
    }
    
    //get the color from the article
    const COLOR = this.props.posts.find(item => item.fields.title === this.props.match.params.artnum).fields.productColor;
    
    Array.prototype.map.call(videos, video => {
      const player = new Player(video, {autopause: true, autoplay: true});
      // set player color
      player.setColor(COLOR);
   
      video.onmouseover = () => player.play();
      video.onmouseout = () => player.pause();
      // }

      // set it so the video only plays when users scrolls past
      
    });
  }
  isInViewport = element => {
    var rect = element.getBoundingClientRect();
    var html = document.documentElement;
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || html.clientHeight)
    );
  }
  render () {
    const { posts, includes } = this.props;
    const selectedArticle = posts[0];
    let logo, featuredImage;
    
    if(!!selectedArticle) {
        logo = 'https:' + includes.find(include => include.sys.id === selectedArticle.fields.logo.sys.id).fields.file.url;
        featuredImage = 'https:' + includes.find(include => include.sys.id === selectedArticle.fields.featuredImage.sys.id).fields.file.url;
    }
    return (
      <div>
        {selectedArticle ?
          <section>
            <Nav color={selectedArticle.fields.productColor} />
            <Header color={selectedArticle.fields.productColor} url={featuredImage} />
            <ContentWrap>
              <SidebarWrap>
                <ArticleInfo>
                  <div className="header-info-content">
                    <Logo src={logo} />
                    <p className="product-title">{selectedArticle.fields.title}</p>
                    <p className="country">{selectedArticle.fields.country}</p>
                    <div className="info"><p>Analysed {moment(selectedArticle.fields.date).format('MMMM Do YYYY')}</p><p><Link to="/about">{selectedArticle.fields.author}</Link></p></div>
                  </div>
                </ArticleInfo>
                {/* <Navigator>

                </Navigator> */}
              </SidebarWrap>
              <EntryBody color={selectedArticle.fields.productColor}>
                { this.state.titles.map(title => 
                    <ScrollLink to={title.pos}>{title.title}</ScrollLink>
                  )
                }
                <ReactMarkdown source={selectedArticle.fields.body} />
                <SocialLinkWrap>
                  <SocialLink 
                    target="_blank" 
                    href={`https://www.facebook.com/sharer.php?u=https://www.underglass.io/read/${this.props.match.params.artnum}&source=UnderGlass`}>
                    <img alt="Facebook" src={Facebook} />
                  </SocialLink>
                  <SocialLink 
                    target="_blank" 
                    href={`https://www.linkedin.com/shareArticle?mini=true&url=https://www.underglass.io/read/${this.props.match.params.artnum}&source=UnderGlass`}>
                    <img alt="linkedIn" src={LinkedIn} />
                  </ SocialLink>
                  <SocialLink
                    target="_blank" 
                    href={`https://twitter.com/intent/tweet?url=https://www.underglass.io/read/${this.props.match.params.artnum}&source=UnderGlass`}>
                  <img alt="twitter" src={Twitter} />
                  </ SocialLink>
                  <SocialLink 
                    target="_blank" 
                    href={`mailto:?subject=Under%20Glass%20Product%20Review:${this.props.match.params.artnum}&body=Article:%20https://www.underglass.io/read/${this.props.match.params.artnum}`}>
                  <img alt="email" src={EmailIcon} />
                  </SocialLink>
                </SocialLinkWrap>
                <Email />
              </EntryBody>
            </ ContentWrap>
          </section>
          : <Loader /> }
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { posts: state.posts.all,
      includes: state.posts.includes.Asset,
  };
}

export default connect(mapStateToProps, { fetchSinglePost })(Article);