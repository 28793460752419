import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import styled from 'styled-components';

import { Resources, EmailPage, About, Home, Article } from './index';
const AppWrap = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

class App extends Component {
  render() {
    return (
      <AppWrap>
        <div>
          <Route exact path="/" component={Home} />
          <Route path="/read/:artnum" component={Article} />
          <Route path="/about" exact component={About} />
          <Route path="/signup" exact component={EmailPage} />
          <Route path="/resources" exact component={Resources} />
          {/* <Route path="/feed" exact component={Feed} /> */}
        </div>
      </AppWrap>
    );
  }
}

export default App;
