import React from 'react';
import ReactDOM from 'react-dom';
import registerServiceWorker from './registerServiceWorker';
import { applyMiddleware, createStore } from 'redux';
import { Provider } from 'react-redux';
import promise from 'redux-promise';

import Root from './Root';
import reducers from './reducers';

import './global-style';

 (function(d) {
    var wf = d.createElement('script'), s = d.scripts[0];
    wf.src = 'https://ajax.googleapis.com/ajax/libs/webfont/1.6.26/webfont.js';
    wf.async = true;
    s.parentNode.insertBefore(wf, s);
 })(document);
const createStoreWithMiddleware = applyMiddleware(promise)(createStore);

const render = () => {
    ReactDOM.render(
    <Provider store={
        createStoreWithMiddleware(
            reducers,
            window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
            )}
        ><Root />
    </Provider>, document.getElementById('root'));
    registerServiceWorker();
}
render ();

