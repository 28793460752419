export default {
    dev: {
        API_BASE_URL:'https://preview.contentful.com',
        API_SPACE_ID:'wwfbt9bjsb4y',
        API_TOKEN:'b5bba031026baacf9b05fb5936e7c1c1048e91a560ec8086aa81e89df5cf3696',
        CONTENT_TYPE:'2wKn6yEnZewu2SCCkus4as',
    },
    prod: {
        API_BASE_URL:'https://cdn.contentful.com',
        API_SPACE_ID:'wwfbt9bjsb4y',
        API_TOKEN:'3eca52c15bb7e450c6db50c620dd7d62135b80ce0888322667782cba01ba99c7',
        CONTENT_TYPE:'2wKn6yEnZewu2SCCkus4as',
    }
}