import React from 'react';
import styled from 'styled-components';
import STYLES from '../constants/STYLES';

const StyledLoader = styled.div`
  width: 26px;
  height: 16px;
  border-radius: 2px;
  margin-top: -8px;
  margin-left: -13px;
  z-index: 5000;
  left: 50%;
  top: 50%;
  position: fixed;
  background-color: ${STYLES.black};
  animation-name: spinner; 
  animation-timing-function: linear; 
  animation-iteration-count: infinite; 
  animation-duration: 2s; 
  transform-style: preserve-3d;
  @keyframes spinner { 
    from { 
      transform: rotateY(0deg); 
    } 
    to 
    { 
      transform: rotateY(-360deg); 

    } 
  }
`;

const Loader = () => (
    <StyledLoader />
)

export default Loader;