// Global import
import { injectGlobal } from 'styled-components';

// Local import
// import STYLES from './constants/STYLES';
import styledNormalize from 'styled-normalize'
import STYLES from './constants/STYLES';

injectGlobal`
  ${styledNormalize}
  @import url('https://fonts.googleapis.com/css?family=Work+Sans:400,800');
  body {
    background-color: ${STYLES.background};
    font-family: 'Work Sans', sans-serif;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
  }
  * {
    box-sizing: border-box;
  }
  h1,h2,h3,.display{
    color: ${STYLES.black};
    font-family: 'Work Sans', sans-serif;
    font-variant-numeric: proportional-nums;
  }
  a {
    color: ${STYLES.black};
    text-decoration: underline;
    &:active {
      color: ${STYLES.black};
    }
    &:visited {
      color: ${STYLES.black};
    }
    &:hover {
      text-decoration: underline;
    }
  }
  strong {
    font-weight: 600;
  }
  .display {
    font-size: ${STYLES.display};
    font-family: 'Work Sans', sans-serif;
    color: #383e44;
    font-weight: 800;
    margin: 1.5rem 0 2rem 0;
  }
  p, span {
    padding: 0;
    font-size: 18px;
    font-family: 'Work Sans', sans-serif;
    font-weight: 300;
    color: ${STYLES.bodyText};
  }
  
`;