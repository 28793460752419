import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { fetchPosts, resetPosts } from '../actions/index';
import styled from 'styled-components';
import STYLES from '../constants/STYLES';
import { Email, Loader } from '../components';
import { Logo } from '../assets';

const FeatureImage = styled.div`

  background-image: url(${props => props.url});
  background-repeat: no-repeat;
  background-size: ${props => props.first ? '40%' : '56%'};
  background-position-x: 70%;
  background-position-y: center;
  transition: all .2s ease;
  // background-color: ${props => props.color};
  width: 50%;
  @media (max-width: 1028px) {
    background-size: 40%;
  }
  @media (max-width: 600px) {
    display: none;
  }
`;

const StyledHome = styled.div`
  background: ${STYLES.background};
  text-align: center;
  padding-top: ${STYLES.sPad};
   
  .title {
    .display {
      margin-bottom: ${STYLES.mPad};
    }
    margin-bottom: ${STYLES.xlPad};

  }
  .links {
    margin: ${STYLES.xlPad} 0;
    a:not(:last-child) { 
      margin-right: ${STYLES.mPad};
    }
    a:hover {
      background: ${STYLES.black};
      color: ${STYLES.background};
    }
  }
  .spread {
    background-color: #ecf0f3;
    padding-top: 20px;
    padding-left: 2px;
    margin-top: 32px;
    width: 100px;
    text-align: center;
    height: 94px;
    margin: 32px auto 32px auto;
    .line {
      font-size: 18px;
      color: ${STYLES.black};
      text-transform: uppercase;
      line-height: 8px;
      margin: 0;
    }
    .one {
      letter-spacing: 3px;
    }
    .two {
      letter-spacing: 4px;
    }
    &:after {
      content: " ";
      width: 17px;
      margin: 24px auto 32px auto;
      display: block;
      height: 1px;
      transform: translateX(-2px);
      background-color: ${STYLES.black};
    }
  }
`;

function hexToRgbA(hex, opacity){
    var c;
    if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
        c= hex.substring(1).split('');
        if(c.length=== 3){
            c= [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c= '0x'+c.join('');
        return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+','+opacity+')';
    }
    throw new Error('Bad Hex');
}

const LoadWrap = styled.div`
  width: 100%;
  padding: 200px;
  display: relative;
`;

const PostListItem = styled.div`
  text-align: left;
  display: flex;
  border-radius: 8px; 
  background-color: ${STYLES.background};
  cursor: pointer;
  width: ${props => props.first ? '100%' : '45%'};
  overflow: hidden;
  margin-bottom:${STYLES.xxlPad};
  // box-shadow: 0 2px 12px 0 ${props => hexToRgbA(props.color, 0.15)};
  border: 1px solid  ${props => hexToRgbA(props.color, 0.2)};
  transition: all .2s;
  position: relative;
  z-index: 5; 
  .display {
    font-size: ${props => props.first ? '68px' : STYLES.display};
    line-height: ${props => props.first ? '.8' : '1'};
    margin-top: ${STYLES.lPad};
    margin-bottom: 0;
  }
  .subtitle {
    font-weight: 500;
    font-size: 12px;
    margin: 24px 0 0 0;
    line-height: 0;
    color: ${STYLES.darkGray};
    letter-spacing: 1.1px;
    text-transform: uppercase;
  }
  &:hover {
    background: ${STYLES.background};
    z-index: 1000;
    transform: scale(1.02);
    :after {
      background-color: ${props => hexToRgbA(props.color, 0.03)};
    }
    .read-line {
      .arrow-line {
        width: 14px;
      }
      > span {
        margin-left: 8px;
      }
    }
    .display, p {
      color: ${props => props.color};
    }
    &:before { 
      border-width: 0 340px 340px 0;
    }
  }
  .display, p {
    transition: all .2s;
  }
  :before {
    content: " ";
    z-index: -10;
    position: absolute;
    transition: all .2s ease-out;
    top: 0;
    right: -40px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 350px 350px 0;
    border-color: transparent ${props => props.color} transparent transparent;

  }
  :after {
    content: " ";
    background-color: transparent;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -900;
  }
  @media (max-width: 1028px) {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  @media (max-width: 600px) {
    .read-line { display: none; }
    &:before {
      content: none;
    }
  }
  `;

const LogoContainer = styled.div`
  position: relative;
  margin: 1rem auto 0 auto;
  width: 180px;
  height: 142px;
  overflow: hidden;
  border-radius: .5rem;

  .image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .content {
    position: absolute;
    top: 50%; left: 50%;
    font-family: sans-serif;
    font-weight: bolder;
    font-size: 16rem;
    transform: translate(-50%,-50%);
    z-index: 100;
    opacity: .85;
    color: white;
  }

  .shard {
    position: absolute;
    width: 100%;
    height: 100%;
    background: url(${Logo});
    background-repeat: no-repeat;
    background-size: 100%;
    z-index: 5;
    transition: all .1s cubic-bezier(0.075, 0.82, 0.165, 1);;
  }

  .shard--shadow, .shard--light {
    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: 0;
      background: rgba(0,0,0,0);
      transition: all .1s cubic-bezier(0.075, 0.82, 0.165, 1);;
    }
  }

  .shard--light {
    &:after {
      background: all .1s cubic-bezier(0.075, 0.82, 0.165, 1);;
    }
  }

  .shard-01, .shard-02, .shard-03 {
    background-position: 0 0;
    background-size: 100%;
  }

  .shard-01 {
    clip-path: polygon(0 0, 100% 0, 100% 62%, 0 28%);
    z-index: 10;
  }
  .shard-02 {
    clip-path: polygon(40% 0, 100% 103%, 100% 0);
    z-index: 20;
  }
  .shard-03 {
    clip-path: polygon(100% 16%, -11% 100%, 100% 100%);
    z-index: 30;
  }

  .image:hover {
    .shard-01 {
      background-position: -5px -5px;
      background-size: 100%;

    }

    .shard-02 {
      background-position: -10px 0;
      background-size: 106%;
    }

    .shard-03 {
      background-position: 0 -10px;
      background-size: 106%;
    }
    
    .shard--shadow, .shard--light {
      &:after {
        opacity: 1;    
      }
    }
  }


`;
const PostInfo = styled.section`
  width: 50%;
  padding: ${props => props.first ? '108px 48px 108px 48px' : '48px 48px 58px 48px'};
  img {
    width: 32px;
  }
  @media (max-width: 600px) {
    width: 100%;
    text-align: center;
    .display {
      font-size: 35px;
    }
    padding: 40px 28px;
  }
  .slug {
    line-height: 1.6;
    margin-top: 12px;
    margin-bottom: 0;
    width: 130%;
    @media (max-width: 600px) {
      margin: 1rem auto 0 auto;
      width: 100%;
    }
  }
`;

const PostWrap = styled.section`
  display: flex;
  flex-wrap: wrap;
  max-width: 1168px;
  width: 96%;
  margin: 0 auto;
  justify-content: space-between;
  @media (max-width: 1028px) {
    display: block;
  }
`;
class Home extends Component {
  componentWillMount() {
    this.props.fetchPosts();
  }
  componentWillUnmount() {
    this.props.resetPosts();
  }
  componentWillReceiveProps(nextProps) {
    const originalPath = this.props.location.pathname;
    const changedPath = nextProps.location.pathname;
    if (originalPath !== changedPath) {
      window.scrollTo(0, 0);
    }
  }
  renderPosts() {
    const { posts, includes, history } = this.props;
    if(includes[0] && posts[0]) {
      return this.props.posts.map((post, index) => {
        return (
          <PostListItem first={index===0} onClick={() => history.push('/read/' + post.fields.title)} key={post.sys.id} color={post.fields.productColor}>
            <PostInfo first={index===0}>
              <img alt="preview app" src={'https:' + includes.find(include => include.sys.id === post.fields.logo.sys.id).fields.file.url} />
              <p className="subtitle">{post.fields.country}</p>
              <p className="display">{post.fields.title}</p>
              <p className="slug">{post.fields.slug}</p>
              
              {/* <div className="read-line">
                <div className="front-line" />
                <span>Read</span>
                <div className="arrow-line" />
              </div> */}
            </PostInfo>
            <FeatureImage first={index===0} color={post.fields.productColor} url={'https:' + includes.find(include => include.sys.id === post.fields.featuredImage.sys.id).fields.file.url} />
          </PostListItem>
        );
      });
    } else { return <LoadWrap><Loader /></LoadWrap> }
  }
  render() {
    return (
      <div>
      
        <StyledHome>
          <div className="title">
            <LogoContainer>
            <div className="image">
              <div className="content"></div>
              <div className="shard shard-01"></div>
              <div className="shard shard-02 shard--light"></div>
              <div className="shard shard-03 shard--shadow"></div>
              <div className="shard"></div>
            </div>
            </LogoContainer>
          <p>An analysis of the world's best digital products. </p>
          <div className="links">
            <p>
              {/* <Link to="/resources">Resources</Link>  */}
              <Link to="/about">About</Link>
              <Link to="/signup">Subscribe</Link>
            </p>
          </div>
          </div>
          <PostWrap>
            {this.renderPosts()}
          </PostWrap>
          <Email />
        </StyledHome>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return { posts: state.posts.all,
      includes: state.posts.includes.Asset, };
}
export default connect(mapStateToProps, { fetchPosts, resetPosts })(Home);