export default {
  // TYPE (1.613 RATIO)
  body: '16px',
  h2: '26px',
  h1: '42px',
  display: '52px',
  //COLOR
  lightGray: '#EBEDF0',
  Gray: '#89919c',
  black: '#383e44',
  opaqueGray: 'rgba(0,0,0,0.05)',
  darkGray: '#9198A1',
  bodyText: '#454d56',
  background: '#FCFCFC',
  /* Rectangle Copy 2: */
  // SPACING 
  sPad: '8px',
  mPad: '16px',
  lPad: '24px',
  xlPad: '32px',
  xxlPad: '64px',
  maxWidth: '1280px',
  maxBodyWidth: 640,
  // RESPONSIVE
  // mobile
  mobBreak: '600px',
  // tablet
  tabBreak: '890px',
  lTabBreak: '1024px',
  // desktop
  deskBreak: '1100px'

}
