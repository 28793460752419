import React, { Component } from 'react';
import { Email } from '../components';
import styled from 'styled-components';
import STYLES from '../constants/STYLES';
import Nav from '../components/Nav';

const StyledModal = styled.section`
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    text-align: center;
    margin-top: 14rem;
    background-color: ${STYLES.background};
    > div {
        display: inline-block;
        transform: translateY(-50%);
        top: 50%;
    }
`;

class EmailPage extends Component {
    render() {
        return(
            <div>
            <Nav fixed />
                <StyledModal>
                    <div>
                        <Email />
                    </div>
                </StyledModal>
            </div>
        )
    }
};

export default EmailPage;