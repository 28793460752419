import React from 'react';
import styled from 'styled-components';
import STYLES from '../constants/STYLES';
import Nav from '../components/Nav';
const StyledAbout = styled.div`
    max-width: ${STYLES.maxBodyWidth}px;
    margin: 40px auto 0 auto;
    h1 {
        margin-top: 3rem;
    }
    p {
        margin-top: ${STYLES.xlPad};
        line-height: 1.65;
    }
    padding-bottom: 48px;
    @media (max-width: 600px) {
        padding-left: 16px;
        padding-right: 16px;
    }
`;
const About = () => (
    <div>
        <Nav fixed />
        <StyledAbout> 
            <h1>What is this?</h1>
            <p>Under Glass provides inspiration through a balanced and in-depth view of great digital product design happening worldwide and how those products connect with people.</p>
            <p>All too often, attention is paid to what's big or new over what works. We believe that products in-between, far away or that may not immediately appeal to people working in technology are worth a closer look.</p>
            <h1>Who made it?</h1>
            <p><a href="https://twitter.com/robbmcm" rel="noopener noreferrer" target="_blank">Rob McMackin</a> is an Irish designer who works at <a rel="noopener noreferrer" href="https://slite.com/" target="_blank">Slite</a> and builds side projects like this one and <a rel="noopener noreferrer" href="http://boolikorean.com" target="_blank">Booli</a>, a game for Korean learners.</p>
            <p><a href="https://twitter.com/DerekNguyen10" rel="noopener noreferrer" target="_blank">Derek Nyugen</a> is a Vietnamese freelance designer also living in Seoul with his wife and daughter. He enjoys hacking with design and code to push his craft to new levels and building awesome <a rel="noopener noreferrer" href="https://github.com/d4rekanguok/sketch-slicer" target="_blank">plugins</a> to improve his workflow.</p>
            <h1>Contact</h1>
            <p>Questions, feedback or interested in working with us? Get in touch through <a rel="noopener noreferrer" href="mailto:rob@underglass.io">rob@underglass.io</a>.</p>
        </StyledAbout>
    </div>
);

export default About;