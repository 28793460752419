import React, { Component } from 'react';
import Feed from 'rss-to-json';
import styled from 'styled-components';
import STYLES from '../constants/STYLES';
import moment from 'moment';
import { Link } from 'react-router-dom';

const StyledHome = styled.div`
  text-align: center;
  padding-top: ${STYLES.sPad};
  .title {
    .display {
      margin-bottom: ${STYLES.mPad};
    }
    margin-bottom: ${STYLES.xlPad};

  }
  .links {
    a:not(:last-child) { 
      margin-right: ${STYLES.mPad};
    }
    a:hover {
      background: ${STYLES.black};
      color: ${STYLES.background};
    }
  }
  .spread {
    margin-top: ${STYLES.xlPad};
    .line {
      font-size: 24px;
      text-transform: uppercase;
      line-height: 24px;
      margin: 0;
    }
    .one {
      letter-spacing: 3px;
    }
    .two {
      letter-spacing: 4px;
    }
    &:after {
      content: " ";
      width: 24px;
      margin: 32px auto 32px auto;
      display: block;
      height: 2px;
      transform: translateX(-3px);
      background-color: ${STYLES.black};
    }
  }
`;


const PostListItem = styled.a`
  text-align: left;
  cursor: pointer;
  max-width: 450px;
  text-decoration: none;
  display: block;
  margin: 0 auto;
  border: 0px solid transparent;
  transition: all .2s;
  position: relative;
  z-index: 5; 
  padding: 20px;
  box-shadow: 0 0 50px 0 rgba(0,0,0,0);
  &:hover {
    z-index: 1000;
    transform: scale(1.02);
    margin-top
  }
  :before {
    content: " ";
    z-index: -10;
    position: absolute;
    transition: all .2s ease-out;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 50px 50px 0;
    border-color: transparent ${STYLES.Gray} transparent transparent;
  }
  a p, a:visited p { 
    text-decoration: none;
    
  }
  .date {
    font-size: 12px;
    text-transform: uppercase;
    display: block;
    letter-spacing: 1px;
    margin-bottom: ${STYLES.sPad};
    text-decoration: none !important;
  }
  .date:visited {
    text-decoration: none;
  }
  @media (max-width: 600px) {
    .read-line { display: none; }
    &:before {
      content: none;
    }
  }
  `;


class Resources extends Component {
  state = {
    items: [],
    loading: true
  }
  loadRSS = () => {
    const rss = Feed.load('https://raindrop.io/collection/3838952/feed', (err, rss) => {
      return rss; 
    });

    // it never gets a chance to load maybe?
    // make new function that gets metadata, make that one refresh until loadrss is done
    if (!rss) {
      return setTimeout(() => {
        this.loadRSS();
      }, 400);
    }
    return rss;
  }

  componentDidMount() {
    //const rss = this.loadRSS();
    // const items = await rss.items.map(async item => {
    //   const metadata = await urlMetadata(item.link);
    //   return {
    //     url: metadata.url,
    //     image: metadata.image,
    //     title: metadata['og:title'],
    //     description: metadata['og:description'],
    //   }
    // });
    this.setState({ loading: false })
    
  }
  renderPosts = () => {
    return (
      <div>
        {this.state.items.map(item => (
          <PostListItem href={item.url}>
              <p className="date">{moment(item.created).format('DD MMM YYYY')}</p>
              <span>{item.title}</span>
              <span>{item.description}</span>
          </PostListItem>
        ))}
      </div>
    )
  }
  render() {
    const { loading } = this.state;
    return (
      <div>    
        <StyledHome>
          <div className="title">
          <div className="spread">
          <p className="line one">Under</p>
          <br />
          <p className="line two">Glass</p>
          </div>
          </div>
          <p>Handpicked analytical lenses on products</p>
          <div className="links">
            <p><Link to="/resources">Resources</Link> <Link to="/about">About</Link> <Link to="/signup">Subscribe</Link></p>
          </div>
          {loading ? 'Loading' : this.renderPosts() }
        </StyledHome>
      </div>
      
    );
  }

}
export default Resources;