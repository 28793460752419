import { FETCH_POSTS, FETCH_SINGLE_POST, FETCH_ASSETS, RESET_POSTS } from '../actions/index';

const INITIAL_STATE = { all: [], includes: {Asset: []} };
export default function(state = INITIAL_STATE, action) {
  switch(action.type) {
  case FETCH_POSTS:
    return { ...state, all: action.payload.data.items, includes: action.payload.data.includes };
  case FETCH_SINGLE_POST:
    return { ...state, all: action.payload.data.items, includes: action.payload.data.includes };
  case FETCH_ASSETS:
    return { ...state, assets: action.payload.data.Asset };
  case RESET_POSTS:
    return INITIAL_STATE;
  default:
    return state;
  }
}