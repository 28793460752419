import React, { Component } from 'react';
import styled from 'styled-components';
import STYLES from '../constants/STYLES';

const StyledForm = styled.div`
  text-align: center;
  button {
    padding: 16px;
    background: ${STYLES.black};
    color: #fff;
    border: 0;
    font-family: 'Work Sans', sans-serif;
    outline: none;
    cursor: pointer;
    &:hover {
      background-color: #4E4C58;
    }
  }
  input {
    font-family: 'Work Sans', sans-serif;
    padding: 16px;
    border: 0;
    outline: none;
    margin-top: 1rem;
  }
  h2 {
    line-height: 32px;
    &:after {
      margin: 1rem auto 0 auto;
    }
  }
  padding: 4rem 0;
`
class Email extends Component {
  render() {
    return(
      <StyledForm className='tooltip center'>
          <div>
            <h2>Learn from great products worldwide. <br/> Get takeaways in your inbox.</h2>

          </div>
          <form action="https://robmcmackin.us12.list-manage.com/subscribe/post?u=0907bcabbf38a76d75945f031&amp;id=3c464c0771" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" target="_blank" noValidate>
          <input type="email" name="EMAIL" id="mce-EMAIL" placeholder="Your Email" required />
            <button type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe">Subscribe</button>
          </form>
      </StyledForm>

    )
  }
}
export default Email;
