import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { App } from './containers';

class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    console.log(prevProps);
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0)
      console.log('derp')
    }
  }

  render() {

    return this.props.children
  }
}

const Root = () => (
  <BrowserRouter>
    <ScrollToTop>
      <App />
    </ScrollToTop>
  </BrowserRouter>
);

// Root.propTypes = {
//   history: PropTypes.objectOf(PropTypes.any).isRequired,
// };

export default Root;